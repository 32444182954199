import React from 'react';
import './Nav.css'

function Nav() {
    return (
        <div className="Nav">
            <div className="Banner">
                <p className="Title">四方花田</p>
                <p className="Subtitle">结庐在人境，而无车马喧。</p>
                <div className="Description">
                    <p>饱受当地居民好评，吸引无数游客的线上预约制餐厅</p>
                    <p>豆瓣酱 9.9&emsp;&emsp;花生点评 9.8&emsp;&emsp;上海中学美食榜第1名</p>
                </div>
            </div>
            <Navigation/>
        </div>
    );
}

function Navigation() {
    let title = ['关于花田','联系我们','预约登记','交通路线','食客评价']
    let path = ['/','/contact','/book','/transport','/review']
    let anchors = []
    for (let i = 0; i < title.length; i++) {
        let temp = <a href={path[i]} className={ relPath() === path[i] ? 'selected' : 'unselected'} key={"option-"+i}>{title[i]}</a>
        anchors.push(temp);
    }
    return (
        <div className="Navigation" children={anchors}/>
    );
}

export function relPath() {
    const url = document.location.toString();
    const arrUrl = url.split("//");
    const start = arrUrl[1].indexOf("/");
    let relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
    if(relUrl.indexOf("?") !== -1){
        relUrl = relUrl.split("?")[0];
    }
    return relUrl;
}

export default Nav;
import './App.css';
import Nav from './components/Nav.js';
import React from "react";
import Article from "./components/Article";
import Scroller from "./components/Scroller";

function App() {
  return (
    <div className="App">
        <Nav/>
        <div className="ContentArea">
            <Scroller real="no"/>
            <Article/>
            <Scroller real="yes"/>
        </div>
    </div>
  );
}

export default App;

import React from 'react';

function About() {
    return(
        <div>
            <h1>欢迎来到，四方花田的世界。</h1>
            <p>欢迎来到四方花田咖啡店，一个忙碌城市中的世外桃源。我们的理念是为每一位客人提供一个能够真正放松心情、享受生活的地方。在这里，您可以暂时忘却都市的喧嚣，沉浸在宁静、闲适的氛围中，感受到心灵的平静与满足。</p>
            <p>四方花田开张于2022年9月，坐落于淮海公园边，主营咖啡与餐饮。即使店面本身在闹市之中，我们店中温馨配置的隔音墙、暖光灯与绿植会令你感觉如同身置田园。</p>
            <h2>我们的理念</h2>
            <p>
                <b>自然与闲适</b>&emsp;四方花田咖啡店以花田为主题，店内布满各式各样的花卉与绿植，营造出宛如世外桃源般的自然氛围。我们希望每一位客人都能在这片绿意盎然的空间中找到内心的宁静，享受片刻的闲适时光。
            </p>
            <p>
                <b>享受慢生活</b>&emsp;在现代社会快节奏、高压力的背景下，我们致力于为每位客人提供一个能够在忙碌中享受生活的温馨场所。在这里，您可以品味一杯香浓的咖啡，享受精致的甜点，放松身心，感受生活的美好。
            </p>
            <p>
                <b>心贴心服务</b>&emsp;我们的员工经过专业培训，以真诚的微笑和贴心的服务，满足您的每一个需求。无论是闲聊、阅读还是静静地思考，四方花田咖啡店都是您理想的选择。
            </p>
            <h2>本店的文化艺术活动</h2>
            <p>
                店内将不定期举办各类文化艺术活动，如绘画展览、音乐演奏、手工艺品制作等，为您提供更多的艺术享受和文化熏陶。
            </p>
            <p>以下是相关历史活动的详情，如果想要预约演出或展览等请转至<a href='/book'>预约登记</a>页面，感谢您的支持！</p>
        </div>
    )
}

export default About;
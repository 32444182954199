import React from 'react';
import './Article.css'
import About from "./article/About";
import Review from "./article/Review";
import Transport from "./article/Transport";
import Book from "./article/Book";

function Article() {
    let article_content;
    switch (relPath()) {
        case '/':
            article_content = <About/>
            break;
        case '/transport':
            article_content = <Transport/>
            break;
        case '/book':
            article_content = <Book/>
            break;
        case '/review':
            article_content = <Review/>
            break;
        default:
            article_content = <div><h2>警告：Falling Back to Default. This Warning is Caused Either Because the Page wasn't Written or Because You Have Entered a Wrong Link. Please Reselect an Option Above.</h2><About/></div>
    }
    return (
        <div className="ArticleMain">
            {article_content}
        </div>
    )
}

export function relPath() {
    const url = document.location.toString();
    const arrUrl = url.split("//");
    const start = arrUrl[1].indexOf("/");
    let relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
    if(relUrl.indexOf("?") !== -1){
        relUrl = relUrl.split("?")[0];
    }
    return relUrl;
}

export default Article;
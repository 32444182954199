import React from 'react';

function Transport() {
    return(
        <div>
            <h1>地理位置与交通信息</h1>
            <h2><b>注意：</b>请在前往餐厅前先进行预约！</h2>
            <p>预约将帮助我们为您提供更快、更精准的服务，无论是用餐还是参观展览都是如此。</p>
            <p>请提早一天进行预约，预约后请保管好预约码。我们不接受现场预约。</p>
            <h2>黄浦门店（主店）&nbsp;—&nbsp;淮海中路201号（近淮海公园）</h2>
            <p>附近有大量广场停车场可供停车，不过我们依然建议您绿色出行！</p>
            <p><b>轨道交通</b>&emsp;乘坐
                <span style={{backgroundColor: "#E3002B"}} className="MetroLine">1</span>
                <span style={{backgroundColor: "#626020"}} className="MetroLine">14</span>
                &nbsp;至一大会址·黄陂南路站由&nbsp;<font color="#E3002B">2号口</font>&nbsp;出站向东行走200米。
            </p>
            <p><b style={{opacity: 0}}>轨道交通</b>&emsp;乘坐
                <span style={{backgroundColor: "#0094D8"}} className="MetroLine">8</span>
                <span style={{backgroundColor: "#626020"}} className="MetroLine">14</span>
                &nbsp;至大世界站由&nbsp;<font color="#0094D8">3号口</font>&nbsp;出站沿柳林路向南至淮海中路后向西行走150米。
            </p>
            <p>
                <b>公共交通</b>&emsp;淮海中路嵩山路：&nbsp;42路&nbsp;167路&nbsp;805路&nbsp;911路&nbsp;926路&nbsp;932路&nbsp;南申专线&nbsp;
            </p>
            <p>您也可以通过骑行或步行等方式前往。</p>
            <h2>徐汇门店&nbsp;—&nbsp;百色路912号（近上海植物园）</h2>
            <p>附近可用停车场有徐汇区中心医院地下车库和植物园停车场（在建，略远），如果可以的话，请支持绿色出行噢！</p>
            <p><b>轨道交通</b>&emsp;乘坐
                <span style={{backgroundColor: "#CAB48F"}} className="MetroLine">15</span>
                &nbsp;至华东理工大学站由&nbsp;<font color="#CAB48F">2号口</font>&nbsp;出站沿百色路向东行走900米/乘坐957路。
            </p>
            <p><b style={{opacity: 0}}>轨道交通</b>&emsp;乘坐
                <span style={{backgroundColor: "#B0D478"}} className="MetroLine">23</span>
                &nbsp;至上海植物园站由&nbsp;<font color="#B0D478">未编号出口</font>&nbsp;出站沿龙吴路向南至百色路后向西行走600米。
            </p>
            <p>由于距离遥远，我们不建议您通过轨道交通前往。</p>
            <p>
                <b>公共交通</b>&emsp;百色路龙川北路：&nbsp;720路&nbsp;824路&nbsp;973路&nbsp;
            </p>
            <p><b style={{opacity: 0}}>公共交通</b>&emsp;龙川北路百色路：&nbsp;192路&nbsp;957路&nbsp;南南线&nbsp;</p>
            <p>您也可以通过骑行或步行等方式前往。</p>
        </div>
    )
}

export default Transport;

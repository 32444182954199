import React, { useEffect, useState } from 'react';
import './Scroller.css';

function Scroller(props) {
    const { real } = props;
    const [scrollCount, setScrollCount] = useState(0); // 状态变量用于触发重新渲染

    const handleScroll = () => {
        console.log("scrolled");
        // 更新状态来触发重新渲染
        setScrollCount(prevCount => prevCount + 1);
    };

    useEffect(() => {
        if (document.documentElement.scrollTop <= 200 || real !== 'yes') {
            window.addEventListener("scroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [real]); // 这里也可以加入scrollCount以更新

    const backTop = () => {
        document.documentElement.scrollTop = 0
    }

    if (document.documentElement.scrollTop > 300 && real === 'yes') {
        return (
            <div className="RightScrollBar">
                <div className="ReturnButton" onClick={backTop}>↑ 返回顶部</div>
            </div>
        );
    } else {
        return (
            <div className="RightScrollBar"></div>
        );
    }
}

export default Scroller;

import React, { useState } from 'react';

function Review() {
    return (
        <div>
            <h1>决定好了吗？那就来提交预约吧！</h1>
            <p>预约将帮助我们为您提供更快、更精准的服务，无论是用餐还是参观展览都是如此。</p>
            <p>请提早一天进行预约，预约后请保管好预约码。我们不接受现场预约。</p>
            <h2>预约表单</h2>
            <BookForm />
            <p>如果有需要特殊照顾的顾客或需要其它服务请下备注，谢谢！</p>
        </div>
    )
}

const BookForm = () => {
    const [result, setResult] = useState({ success: "", message: "" });

    const submit = () => {
        console.log("submit");
        let isSuccess = true;
        let message = "预约提交成功！您的预约码是 "+Math.round(Math.random()*99999999).toString().padStart(8,'0') + "，请妥善保存。";
        let date = new Date()
        let year = date.getFullYear()
        let month = (date.getMonth()+1).toString().padStart(2,'0')
        let day = date.getDate().toString().padStart(2,'0')
        var time = year+'-'+month+'-'+day
        if(time >= document.getElementById('datetime').value) {
            isSuccess = false;
            message = "只能预约明天及之后的日期";
        }
        if(document.getElementById('count').value < 1 || document.getElementById('count').value > 12) {
            isSuccess = false;
            message = "就餐人数请限制在 1 至 12 人之间。"
        }
        if(document.getElementById('count').value %1 !== 0) {
            isSuccess = false;
            message = "我们不接受 " + Math.round(document.getElementById('count').value %1 * 100) / 100 + " 个人来吃饭，因为这可能会吓到其它顾客~"
        }
        if(document.getElementById('username').value === '') {
            isSuccess = false;
            message = "称呼不能为空！"
        }
        if(document.getElementById('contact').value === '') {
            isSuccess = false;
            message = "联系方式不能为空！"
        }
        if(document.getElementById('contact').value === '') {
            isSuccess = false;
            message = "日期不能为空！"
        }
        if (isSuccess) {
            document.getElementById('contact').value = ''
            document.getElementById('username').value = ''
            document.getElementById('count').value = ''
            document.getElementById('datetime').value = ''
            document.getElementById('note').value = ''
            setResult({ success: "success", message: message });
        } else {
            setResult({ success: "failure", message: message });
        }
    }

    return (
        <div className="BookForm">
            <p>预约类型&emsp;
                <select id="group">
                    <option value="1">用餐观展</option>
                    <option value="2">演出场地</option>
                </select></p>
            <p>用餐时间&emsp;<input type="date" id="datetime"></input></p>
            <p>联系方式&emsp;<input type="text" id="contact"></input></p>
            <p>&emsp;&emsp;称呼&emsp;<input type="text" maxLength="4" id="username"></input></p>
            <p>用餐人数&emsp;<input type="number" min="0" max="12" id="count"></input></p>
            <p>用户备注&emsp;<input type="text" id="note"></input>&emsp;<BookSubmitButton submit={submit} /></p>
            <ResultCallback success={result.success} message={result.message} />
        </div>
    )
}

const BookSubmitButton = ({ submit }) => {
    return (
        <button className="BookSubmitButton" onClick={submit}>提&nbsp;交</button>
    )
}

const ResultCallback = (props) => {
    if (props.success === "success") return <p style={{ color: 'green' }}>{props.message}</p>
    else return <p style={{ color: 'red' }}>{props.message}</p>
}

export default Review;

import React from 'react';

function Review() {
    return(
        <div>
            <h1>享用完我们的美食，顾客们怎么说？</h1>
                <p>自四方花田成立以来，收到了许多来自各地顾客的好评，也吸引了许多的回头客，这令我们倍感鼓舞。</p>
                <p>以下是部分在四方花园漂流本中收集到的留言，也欢迎您在享用完美食之后用纸笔留下自己的温暖回忆。不论好评差评，如果您的留言发自真心，我们会向你发送随机无料一份以表对您的支持的感谢！</p>
                <h2>王小姐</h2>
                <p>“四方花田咖啡店真的像一个小花园，充满了绿植和花卉，非常舒适。每次来这里，都能放松身心，忘记工作的烦恼。”</p>
                <h2>李先生</h2>
                <p>“这个地方就像忙碌都市中的一片宁静绿洲，环境非常优雅，咖啡香气四溢，甜点也非常美味。真的很喜欢这里的氛围。”</p>
                <h2>陈小姐</h2>
                <p>“四方花田咖啡店的服务非常贴心，员工们都很友善，感觉像回到了家一样温暖。在这里，我可以静静地看书，享受片刻的宁静。”</p>
                <h2>张先生</h2>
                <p>“每次来到四方花田咖啡店，都能让我放松下来。这里的艺术活动也很丰富，每次都有新的惊喜，真的很喜欢。”</p>
                <h2>刘小姐</h2>
                <p>“店内的花卉布置得非常漂亮，给人一种回归自然的感觉。咖啡的味道很棒，甜点也很精致。是个放松和享受生活的好地方。”</p>
                <h2>赵先生</h2>
                <p>“四方花田咖啡店是我每周必来的地方，特别喜欢这里的宁静和舒适。无论是一个人还是和朋友一起，都能度过愉快的时光。”</p>
                <h2>何小姐</h2>
                <p>“店内的绿植和花卉让我感觉非常放松，像是进入了一个小花园。这里的咖啡和甜点都很好吃，非常推荐！”</p>
                <h2>吴先生</h2>
                <p>“在四方花田咖啡店，我找到了久违的宁静。这里的氛围非常闲适，让我忘却了都市的喧嚣。”</p>
                <h2>周小姐</h2>
                <p>“这是个非常特别的咖啡店，环境优雅，服务贴心。每次来这里，都能让我找到内心的平静。”</p>
                <h2>黄先生</h2>
                <p>“四方花田咖啡店让我感受到了一种与众不同的闲适氛围。这里的艺术活动很有趣，咖啡和甜点也非常好吃。”</p>
        </div>
    )
}

export default Review;